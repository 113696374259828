import React, {useState, useEffect} from 'react';

const Range = ({filter, group, dispatch, multiScreen}) => {
  const [minValue, setMinValue] = useState(filter?.value?.min || '');
  const [maxValue, setMaxValue] = useState(filter?.value?.max || '');

  useEffect(() => {
    if (
      !filter?.filterable?.default?.floor ||
      (filter?.value?.min && filter?.value?.min < filter?.filterable?.default?.floor)
    ) {
      return;
    }
    if (filter?.filterable?.default?.floor) {
      setMinValue(filter?.filterable?.default?.floor);
      dispatch({
        type: 'UPDATE_FILTER_VALUES',
        payload: {
          updatedValue: filter?.filterable?.default?.floor,
          key: filter?.key,
          minMax: 'min',
          group,
        },
      });
    }
  }, []);
  useEffect(() => {
    if (
      !filter?.filterable?.default?.max ||
      (filter?.value?.max && filter?.value?.max < filter?.filterable?.default?.max)
    ) {
      return;
    }
    if (filter?.filterable?.default?.max) {
      setMaxValue(filter?.filterable?.default?.max);
      dispatch({
        type: 'UPDATE_FILTER_VALUES',
        payload: {
          updatedValue: filter?.filterable?.default?.max,
          key: filter?.key,
          minMax: 'max',
          group,
        },
      });
    }
  }, []);

  return (
    <div className="flex flex-col grow gap-2">
      <div className="flex items-center gap-2 justify-between">
        <label
          htmlFor="symbol"
          className={`${multiScreen ? 'text-xs font-bold' : 'text-xs'} block font-small text-header-color`}
        >
          {filter?.title}
        </label>
        <div>
          <button
            className="reset-filter-range"
            type="button"
            onClick={() => {
              dispatch({
                type: 'RESET_RANGE_GROUP',
                payload: {
                  key: filter?.key,
                  group,
                },
              });
              setMinValue('');
              setMaxValue('');
            }}
          >
            Reset Filter
          </button>
        </div>
      </div>
      <div className="flex gap-2">
        {filter?.filterable?.hideMin ? null : (
          <div className="flex flex-col gap-2 w-full">
            {!multiScreen
              ? ''
              : // <label
                //   htmlFor="name"
                //   className="block text-sm font-medium text-[#70769B] range-label"
                // >
                //   Min
                // </label>
                null}
            <div
              style={{background: 'rgba(148, 196, 242, 0.1)'}}
              className="relative rounded-[50px] border-none text-[12px] leading-[15px] text-[#70769B] px-2 py-1 shadow-sm focus-within:ring-1 focus-within:ring-transparent focus-within:border-gray-300 "
            >
              <input
                step="0.01"
                min={filter?.filterable?.floor ?? 0}
                type={filter?.type ? (filter?.type === 'money' ? 'number' : filter?.type) : 'number'}
                name={filter?.key}
                id={filter?.key}
                className={`${
                  multiScreen ? 'text-sm' : 'text-md'
                } block w-full border-0 py-0 px-1 bg-transparent relative rounded-[50px] border-none text-[12px] leading-[15px] text-[#70769B] focus:ring-0`}
                placeholder={
                  filter?.filterable?.placeholder?.low ??
                  `Min ${filter?.filterable?.floor ? `(${filter?.filterable?.floor} minimum)` : ''}`
                }
                onChange={(e) => {
                  const value = Number(e.target.value);
                  const minValueNum = Number(filter?.filterable?.floor);

                  dispatch({
                    type: 'UPDATE_FILTER_VALUES',
                    payload: {
                      updatedValue: value < minValueNum && !Number.isNaN(minValueNum) ? minValueNum : value ?? '',
                      key: filter?.key,
                      minMax: 'min',
                      group,
                    },
                  });
                  setMinValue(value < minValueNum && !Number.isNaN(minValueNum) ? minValueNum : value ?? '');
                }}
                value={minValue || ''}
              />
            </div>
            {multiScreen ? (
              <label
                htmlFor="name"
                className={`${
                  multiScreen ? 'range-label-multi' : ''
                } block text-xs font-medium text-[#70769B]  range-label`}
              >
                Min
              </label>
            ) : null}
          </div>
        )}
        {filter?.filterable?.hideMax ? null : (
          <div className="flex flex-col gap-2 w-full">
            {!multiScreen
              ? ''
              : // <label
                //   htmlFor="name"
                //   className="block text-sm font-medium text-[#70769B]  range-label"
                // >
                //   Max
                // </label>
                null}
            <div
              style={{background: 'rgba(148, 196, 242, 0.1)'}}
              className="relative rounded-[50px] border-none text-[12px] leading-[15px] text-[#70769B] px-2 py-1 shadow-sm focus-within:ring-1 focus-within:ring-transparent focus-within:border-gray-300 "
            >
              <input
                step="0.01"
                type={filter?.type ? (filter?.type === 'money' ? 'number' : filter?.type) : 'number'}
                name={filter?.key}
                id={filter?.key}
                min={0}
                className={`${
                  multiScreen ? 'text-sm' : 'text-md'
                } block w-full border-0 py-0 px-1 bg-transparent relative rounded-[50px] border-none text-[12px] leading-[15px] text-[#70769B] focus:ring-0`}
                placeholder={filter?.filterable?.placeholder?.high ?? 'Max'}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  const maxValueNum = Number(filter?.filterable?.minMax);

                  dispatch({
                    type: 'UPDATE_FILTER_VALUES',
                    payload: {
                      updatedValue: value > maxValueNum && !Number.isNaN(maxValueNum) ? maxValueNum : value ?? '',
                      key: filter?.key,
                      minMax: 'max',
                      group,
                    },
                  });
                  setMaxValue(value > maxValueNum && !Number.isNaN(maxValueNum) ? maxValueNum : value ?? '');
                }}
                value={maxValue || ''}
              />
            </div>
            {multiScreen ? (
              <label
                htmlFor="name"
                className={`${
                  multiScreen ? 'range-label-multi' : ''
                } block text-xs font-medium text-[#70769B]  range-label`}
              >
                Max
              </label>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default Range;
