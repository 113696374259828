import React, {useState, useEffect} from 'react';
import {Switch} from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Toggle = ({filter, group, dispatch, multiScreen}) => {
  const [enabled, setEnabled] = useState(filter?.value);

  useEffect(() => {
    setEnabled(filter?.value);
  }, [filter?.value]);

  const handleToggleValue = () => {
    dispatch({
        type: 'UPDATE_FILTER_VALUES',
        payload: {
          updatedValue: !enabled,
          key: filter?.key,
          group,
        },
      });
    setEnabled((prev) => !prev);
  };

  return (
    <div className="flex flex-col gap-2 order-last ">
      <label
        htmlFor="symbol"
        className={`${multiScreen ? 'text-xs font-bold' : 'text-xs'} block font-small text-header-color`}
      >
        {filter?.filterable?.title ?? filter?.title}
      </label>
      <div>
        <div className="flex items-center gap-2 text-[#70769B]">
          <Switch
            checked={enabled}
            onChange={handleToggleValue}
            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-none focus:ring-transparent focus:ring-offset-2"
          >
            <span className="sr-only">Toggle {filter?.title}</span>
            <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-main-bg" />
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? 'bg-spyder-green-100' : 'bg-gray-200',
                'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
              )}
            />
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Toggle;
