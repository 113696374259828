import React from 'react';
import SpyderIcon from '../../Icons/spyder_icon.png';

const LoadingUser = (props) => {
  const messageData = props;
  const message = messageData.message ? messageData.message : 'Loading...';
  
  return (
    <div id="loading-user" className="flex w-full h-full fixed justify-center items-center flex-wrap">
      <div className="loading-user-wrapper">
        <div className="mb-2 text-center animate-pulse">
          <div className="loader"/>
          {/* <img src={SpyderIcon} alt="Spyder Scanner" className="inline-block" /> */}
        </div>
       {message ? <span>{message}</span> : null}  
      </div>
    </div>
  );
};

export default LoadingUser;
