/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import {visuallyHidden} from '@mui/utils';
import {useParams} from 'react-router-dom';

const ScannerTableHeader = React.memo(
  ({order, orderBy, onRequestSort, tableHeaders, multiScreen, alignColumns}) => {
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    const params = useParams();

    return (
      <TableHead>
        <TableRow>
          {tableHeaders.filter((column) => !column?.hideColumn).map((header, index) => (
            <TableCell
              key={`${header.key}-${index * 2}`}
              align={alignColumns ?? 'center'}
              padding="normal"
              sortDirection={orderBy === header.title ? order : false}
   
            >
              <TableSortLabel
                active={orderBy === header.key}
                direction={orderBy === header.key ? order : 'asc'}
                onClick={createSortHandler(header.key)}
                className={`${
                  orderBy !== header.key && multiScreen ? 'not-active whitespace-nowrap' : 'whitespace-nowrap'
                } table-header-light`}
              >
                {header.title === 'AI' ? '' : header.title
                  .replace('Percent', '%')
                  .replace('Average', 'Avg.')
                  .replace('Volume', 'Vol.')
                  .replace('Count', 'Cnt.')}
                {orderBy === header.key ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  },
  (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps),
);

ScannerTableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default ScannerTableHeader;
