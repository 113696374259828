import React, {useEffect, useRef, useState} from 'react';

let tvScriptLoadingPromise;

const TradingViewWidget = ({symbolToChart, groupToChart, groupCollection, theme}) => {
  const chartRef = useRef(null);
  const container = useRef(null);
  const onLoadScriptRef = useRef();

  const [chartSymbol, setChartSymbol] = useState(null);
  const [chart, setChart] = useState(null);
  //   function syncToTheme(t) {
  //     if (chart) {
  //       chart.applyOptions(themesData[t].chart);
  //     }
  //     if (mainSeries) {
  //       mainSeries.applyOptions(themesData[t].series);
  //     }
  //   }

  const findExchangeBySymbol = (symbol, groupID = 'MTA_DPL') => {
    if (!symbol || !groupCollection || !groupID) return null;
    if (symbol === 'ASHR') {
      return 'AMEX';
    }
    const groupKeys = Object.keys(groupCollection ?? {});
    if (!groupKeys.length) return null;
    const groupData = groupCollection[groupKeys.find((key) => key === groupID)]?.data;
    if (!Object.keys(groupData ?? {}).length) return null;
    return groupData[symbol]?.ListedExchange?.value ?? null;
  };

  useEffect(() => {
    if (symbolToChart) return;
    if (!groupCollection || !groupToChart) return;
    const groupKeys = Object.keys(groupCollection ?? {});
    if (!groupKeys.length) return;
    const groupData = groupCollection[groupKeys.find((key) => key === groupToChart)]?.data;
    if (!Object.keys(groupData ?? {}).length) return;
    const groupDataKeys = Object.keys(groupData);
    if (!groupDataKeys.length) return;
    const firstSymbol = groupDataKeys.sort()[0] ?? null;
    if (!firstSymbol) return;
    const exchange = findExchangeBySymbol(firstSymbol, groupToChart);
    if (!exchange) return;
    setChartSymbol(`${exchange}:${firstSymbol}`);
  }, []);

  useEffect(() => {
    if (!symbolToChart || !groupToChart) return;
    const exchange = findExchangeBySymbol(symbolToChart, groupToChart);
    if (!exchange) return;
    setChartSymbol(`${exchange}:${symbolToChart}`);
  }, [symbolToChart]);

  const createWidget = () => {
    if (document.getElementById('tradingview_3fe4f') && 'TradingView' in window && chartSymbol) {
      // @ts-ignore
      // eslint-disable-next-line new-cap
      const newChart = new window.TradingView.widget({
        autosize: true,
        symbol: `${chartSymbol}`,
        interval: 'D',
        timezone: 'America/New_York',
        theme: theme ?? 'light',
        style: '1',
        locale: 'en',
        enable_publishing: false,
        allow_symbol_change: false,
        container_id: 'tradingview_3fe4f',
        id: 'tradingview_3fe4f',
        withdateranges: true,
        range: '1D',
        hide_side_toolbar: false,
        details: true,
        hotlist: true,
        calendar: true,
        support_host: 'https://www.tradingview.com',
      });
      setChart(newChart);
    }
  };

  useEffect(() => {
    // @ts-ignore
    onLoadScriptRef.current = createWidget;
    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.id = 'tradingview-widget-loading-script';
        script.src = 'https://s3.tradingview.com/tv.js';
        script.type = 'text/javascript';
        // script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
        script.async = true;
        container.current.appendChild(script);
        script.onload = resolve;
      });
    }

    tvScriptLoadingPromise.then(() => {
      if (onLoadScriptRef.current) {
        onLoadScriptRef.current();
      }
    });

    // @ts-ignore
    return () => (onLoadScriptRef.current = null);
  }, [chartSymbol]);

  return (
    <div className="tradingview-widget-container" ref={container} style={{height: 'calc(100% - 30px)', width: '100%', marginTop: '10px'}}>
      <div
        className="tradingview-widget-container__widget"
        id="tradingview_3fe4f"
        ref={chartRef}
        style={{height: '100%', width: '100%'}}
      />
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noreferrer" target="_blank">
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
};

export default TradingViewWidget;
