import React, {useState, useEffect} from 'react';

const NumAbbr = require('number-abbreviate');

const hasNumbers = (t) => {
  const regex = /\d/g;
  return regex.test(t);
};

const displayValue = ({data, dataType}) => {
  if ((!data?.value && data?.value !== 0) || data?.value === '0.00') {
    return <span />;
  }
  const {display} = dataType;
  const {value} = data;
  const formatPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  switch (display) {
    case 'percentage': {
      if (typeof value === 'string' && value?.length && value?.includes('%') && value?.includes('.')) {
        const num = Number(value.replace('%', ''));
        return <span>{`${parseInt(num, 10)}%`}</span>;
      }
      return (value || value === 0) && !Number.isNaN(Number(value)) ? (
        <span>{`${value}%`}</span>
      ) : (
        <span>{typeof value === 'string' && value?.length && value?.includes('%') ? value : ''}</span>
      );
    }

    case 'money-short':
      return value && !Number.isNaN(Number(value)) ? (
        <span>{`$${NumAbbr(Number(value), 2)}`.toUpperCase()}</span>
      ) : null;
    case 'number-short':
      return value && !Number.isNaN(Number(value)) ? <span>{`${NumAbbr(Number(value), 2)}`.toUpperCase()}</span> : null;
    case 'money':
      return value && !Number.isNaN(Number(value)) ? <span>{formatPrice.format(Number(value))}</span> : null;
    case 'number-roundTo':
      return value && !Number.isNaN(Number(value)) ? <span>{`${Number(value).toFixed(2)}`}</span> : null;
    case 'number-round':
      return value && !Number.isNaN(Number(value)) ? <span>{`${Math.round(Number(value))}`}</span> : null;
    default:
      return value && !Number.isNaN(Number(value)) ? <span>{value}</span> : null;
  }
};

const MapTableBody = ({data, dataTypes, yAxisTitle, useYAxisLabel, fillMissingColumns, gridView, order}) => {
  const [dtOrder, setDtOrder] = useState([]);
  if (!dataTypes?.length || !data) {
    return null;
  }
  useEffect(() => {
    if (order?.length) {
      // Sort and Filter for only the dataTypes that are in the order array.
      const ordered = order.map((o) => dataTypes.find((d) => d?.key === o));
      setDtOrder(ordered);
    } else {
      setDtOrder(dataTypes);
    }
  }, [dataTypes, order]);

  return dataTypes?.map((type, index) => {
    const value = data?.[type?.key];

    let missingColumns = Array.isArray(value) ? dataTypes?.length - value?.length : 0;
    if (!value?.length) {
      missingColumns = dataTypes?.length - 1;
    }

    return (
      <tr key={type?.key} className={`${gridView ? 'divide-x divide-gray-300' : ''}`}>
        {yAxisTitle && useYAxisLabel ? (
          index === 0 ? (
            <td className="px-3 py-2 whitespace-nowrap text-sm font-semibold ">{yAxisTitle}</td>
          ) : (
            <td className="px-3 py-2 whitespace-nowrap text-sm font-semibold">
              <span />
            </td>
          )
        ) : null}
        {useYAxisLabel ? (
          <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-[#b2b6ce]">{type?.title}</td>
        ) : null}
        {value?.length && Array.isArray(value) ? (
          value?.map((v) => (
            <td key={`${type?.key}-${v?.value}`} className="px-3 py-2 whitespace-nowrap text-sm text-gray-300">
              {displayValue({data: v, dataType: type})}
            </td>
          ))
        ) : (
          <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-300">
            {displayValue({data: value, dataType: type})}
          </td>
        )}
        {missingColumns > 0 && fillMissingColumns
          ? Array.from({length: missingColumns}).map((_, i) => (
              <td
                key={`missing-${type?.key}-${missingColumns - i}`}
                className="px-3 py-2 whitespace-nowrap text-sm font-semibold"
              >
                <span className="invisible">filler</span>
              </td>
            ))
          : null}
      </tr>
    );
  });
};

const RiskRewardTable = ({riskRewardData, riskRewardDataTypes, riskRewardTitle}) => {
  if (!riskRewardDataTypes?.length || !riskRewardData) {
    return null;
  }
  return (
    <div className="mt-4">
      <div className="flex items-start justify-between flex-col gap-2">
        <div className="power-options">
          <h1>{riskRewardTitle ?? ''}</h1>
        </div>
      </div>
      <div>
        <table className="min-w-full divide-y divide-gray-300 mt-4">
          <thead>
            <tr className="divide-x divide-gray-200">
              {riskRewardDataTypes?.length
                ? riskRewardDataTypes?.map((type) => (
                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold">
                      {type?.title ?? type}
                    </th>
                  ))
                : null}
            </tr>
          </thead>
          <tbody className="divide-y  divide-gray-200">
            {riskRewardData?.length
              ? riskRewardData?.map((d, i) => (
                  <tr key={`risk-reward-${d?.Symbol?.value}-${i * 5} `} className="divide-x divide-gray-300">
                    {riskRewardDataTypes?.map((type) => {
                      const value = d?.[type?.key];
                      return (
                        <td
                          key={`risk-reward-${type?.key}`}
                          className="px-3 py-2 whitespace-nowrap text-sm text-gray-300"
                        >
                          {displayValue({data: value, dataType: type})}
                        </td>
                      );
                    })}
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ReportView = ({reportToView, groupCollection}) => {
  const [reportData, setReportData] = useState(null);
  const [reportTitle, setReportTitle] = useState(null);
  const [reportDataTypes, setReportDataTypes] = useState(null);
  const [riskRewardData, setRiskRewardData] = useState(null);
  const [riskRewardDataTypes, setRiskRewardDataTypes] = useState(null);
  const [riskRewardTitle, setRiskRewardTitle] = useState(null);
  const [riskRewardOrder, setRiskRewardOrder] = useState(null);

  useEffect(() => {
    if (!reportToView || !groupCollection) {
      if (reportData) setReportData(null);
      return;
    }
    if (reportToView && Object.keys(reportToView ?? {}).length) {
      const {group, symbol, useSummary, row} = reportToView;
      if (!group || !symbol) {
        setReportData(null);
        setReportTitle(null);
        setReportDataTypes(null);
        setRiskRewardTitle(null);
        setRiskRewardOrder(null);
        
        return;
      }
      let formattedSymbol = hasNumbers(symbol) ? symbol.split(/[0-9]/)[0] : symbol;
      if (formattedSymbol && typeof formattedSymbol === 'string' && formattedSymbol?.charAt(0) === 'e') {
        formattedSymbol = formattedSymbol.substring(1);
      }

      const collectionData = groupCollection[group];
      // const report = useSummary ? collectionData?.report?.mainReport : collectionData?.report?.report;
      const report = collectionData?.report?.report;
      const riskRewardReport = !useSummary ? collectionData?.report?.riskReward : null;
      const type = row?.Type;
      if (!report && !riskRewardReport) {
        setReportData(null);
        setRiskRewardData(null);
        return;
      }
      const columnMap = {
        Call: 'CallStats.Stats',
        Put: 'PutStats.Stats',
        default: 'Stats.Stats',
      };
      if (riskRewardReport && !useSummary) {
        const riskRewardCollection = groupCollection?.[riskRewardReport];
        if (riskRewardCollection && riskRewardCollection?.rawData?.length && riskRewardCollection?.dataTypes?.length) {
          const riskRewardDTypes = riskRewardCollection?.dataTypes?.filter((v) => v?.key !== '0');
          // Sort and Filter for only the dataTypes that are in the order array.
          const ordered = riskRewardCollection?.asStatsOrder
            ? riskRewardCollection?.asStatsOrder.map((o) => riskRewardDTypes.find((d) => d?.key === o))
            : riskRewardDTypes;
          const foundMatch = riskRewardCollection?.rawData?.find((d) => d?.Symbol?.value === `e${formattedSymbol}` || d?.Symbol?.value === formattedSymbol);
          if (!foundMatch) {
            return;
          }
          const formatted =
            foundMatch?.[columnMap?.[type] ?? columnMap.default] && foundMatch?.[columnMap?.[type] ?? columnMap.default]?.length
              ? foundMatch?.[columnMap?.[type] ?? columnMap.default]?.map((s) => {
                  const keys = Object.keys(s ?? {});
                  if (!keys?.length) {
                    return null;
                  }
                  const formattedKeys = keys.reduce((acc, k) => {
                    const parentKey = k;
                    const foundKey = riskRewardDTypes?.find((d) => d?.key === k);
                    if (foundKey?.type === 'object') {
                      const subKeys = Object.keys(s?.[k] ?? {});
                      if (!subKeys?.length) {
                        return acc;
                      }
                      subKeys.forEach((sk) => {
                        let value = s?.[k]?.[sk] ?? {value: null};
                        if (value?.value === 'nan') {
                          value = {value: null};
                        }
                        acc[`${parentKey}.${sk}`] = value;
                      });
                      return acc;
                    }
                    let value = s?.[k] ?? {value: null};
                    if (value?.value === 'nan') {
                      value = {value: null};
                    }
                    return {
                      ...acc,
                      [k]: value ?? {value: null},
                    };
                  }, {});
                  return {...formattedKeys, Symbol: foundMatch?.Symbol};
                })
              : [];
          if (formatted?.length) {
            setRiskRewardData(formatted);
            setRiskRewardDataTypes(ordered);
            setRiskRewardOrder(riskRewardCollection?.asStatsOrder);
            setRiskRewardTitle(riskRewardCollection?.groupTitle);
          }
        }
      }
      if (report && useSummary) {
        const repCollection = groupCollection?.[report];
        if (!repCollection) {
          return;
        }
        const {groupTitle, orderTypes, dataTypes, data: repData, rawData} = repCollection;
        setReportTitle(groupTitle);
        setReportDataTypes(dataTypes);
        if (rawData?.length && !useSummary) {
          const data = rawData.find((d) => d.Symbol?.value === formattedSymbol);
          if (data) {
            setReportData(data);
          }
        }
        if (useSummary && rawData?.length) {
          if (Object.keys(rawData[0] ?? {})?.length) {
            setReportData(rawData[0]);
          }
        }
      }
    }
  }, [reportToView, groupCollection]);

  if (reportToView && ((!reportData && reportToView?.useSummary) || (!reportToView?.useSummary && !riskRewardData?.length))) {
    return (
      <div>
        <div className="flex items-start justify-between flex-col gap-2">
          <div className="power-options">
            <h1>{reportTitle ?? ''}</h1>
          </div>
          <h2 className="text-xl font-bold">{reportData?.Symbol?.value}</h2>
        </div>
        <div>
          <div className="my-4 text-center">
            <div className="loader" />
          </div>
        </div>
      </div>
    );
  }

  return reportData ? (
    <div>
      <div className="flex items-start justify-between flex-col gap-2">
        <div className="power-options">
          <h2 className="text-[28px] -mb-1 font-bold">{reportData?.Symbol?.value}</h2>
          <h1>{reportTitle ?? ''}</h1>
        </div>
      </div>
      <div>
        <div className=" flow-root">
          <div className="-my-2 overflow-x-hidden ">
            <div className="inline-block min-w-full align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">
                      <span />
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold ">
                      <span />
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <MapTableBody
                    data={reportData}
                    dataTypes={reportDataTypes}
                    yAxisTitle=""
                    useYAxisLabel
                    fillMissingColumns={false}
                    gridView={false}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {!reportToView?.useSummary && riskRewardData?.length ? (
        <RiskRewardTable
          riskRewardData={riskRewardData}
          riskRewardDataTypes={riskRewardDataTypes}
          riskRewardTitle={riskRewardTitle}
          riskRewardOrder={riskRewardOrder}
        />
      ) : null}
    </div>
  ) : !reportToView?.useSummary && riskRewardData?.length ? (
    <RiskRewardTable
      riskRewardData={riskRewardData}
      riskRewardDataTypes={riskRewardDataTypes}
      riskRewardTitle={riskRewardTitle}
      riskRewardOrder={riskRewardOrder}
    />
  ) : null;
};

export default React.memo(ReportView, (prev, next) => {
  const reportToViewSame = JSON.stringify(prev?.reportToView) === JSON.stringify(next?.reportToView);
  return reportToViewSame;
});

// export default ReportView;
