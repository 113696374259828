

import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

const Accordion = ({ title, children }) =>  (
    <div className="flex flex-col justify-start ">
      <Disclosure defaultOpen={false}>
        {({ open }) => (
          <>
            <Disclosure.Button className="max-w-md w-[28rem] flex justify-between text-[12px] text-[#70769B] px-4 py-1.5 shadow-sm rounded-[12px]" style={{background: 'rgba(148, 196, 242, 0.1)'}}>
              <span>{title}</span>
              {open ? (
                <ChevronUpIcon className="w-4 h-4 text-gray-500 ml-2" />
              ) : (
                <ChevronDownIcon className="w-4 h-4 text-gray-500 ml-2" />
              )}
            </Disclosure.Button>
            <Disclosure.Panel
              className="pt-2 text-sm text-gray-500"
            >
              {children}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
);

export default Accordion;

