import React, {useState, useEffect, useContext} from 'react';
import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRefresh} from '@fortawesome/free-solid-svg-icons';
import { orderBy } from 'lodash';
import dayjs from 'dayjs';
import ReportsContext from '../../Context/ReportsContext';

const NumAbbr = require('number-abbreviate');

const displayValue = ({data, dataType}) => {
  if ((!data?.value && data?.value !== 0) || data?.value === '0.00') {
    return <span />;
  }
  const {display} = dataType;
  const {value} = data;
  const formatPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  switch (display) {
    case 'percentage': {
      if (typeof value === 'string' && value?.length && value?.includes('%') && value?.includes('.')) {
        const num = Number(value.replace('%', ''));
        return <span>{`${parseInt(num, 10)}%`}</span>;
      }
      return (value || value === 0) && !Number.isNaN(Number(value)) ? (
        <span>{`${value}%`}</span>
      ) : (
        <span>{typeof value === 'string' && value?.length && value?.includes('%') ? value : ''}</span>
      );
    }

    case 'money-short':
      return value && !Number.isNaN(Number(value)) ? (
        <span>{`$${NumAbbr(Number(value), 2)}`.toUpperCase()}</span>
      ) : null;
    case 'number-short':
      return value && !Number.isNaN(Number(value)) ? <span>{`${NumAbbr(Number(value), 2)}`.toUpperCase()}</span> : null;
    case 'money':
      return value && !Number.isNaN(Number(value)) ? <span>{formatPrice.format(Number(value))}</span> : null;
    case 'number-roundTo':
      return value && !Number.isNaN(Number(value)) ? <span>{`${Number(value).toFixed(2)}`}</span> : null;
    case 'number-round':
      return value && !Number.isNaN(Number(value)) ? <span>{`${Math.round(Number(value))}`}</span> : null;
    default:
      return value && !Number.isNaN(Number(value)) ? <span>{value}</span> : null;
  }
};

const formatSymbolData = (report, reportSettings, tickerData) => {
  const formattedData = {
    riskRewardData: null,
    dataTypes: null,
    orderTypes: null,
    groupTitle: null,
    ranks: null,
  };
  if (report && Object.keys(report ?? {}).length && reportSettings && reportSettings?.dataType?.length) {
    const riskRewardDTypes = reportSettings?.dataType?.filter((v) => v?.key !== '0');
    // Sort and Filter for only the dataTypes that are in the order array.
    const ordered = report?.asStatsOrder
      ? report?.asStatsOrder.map((o) => riskRewardDTypes.find((d) => d?.key === o))
      : riskRewardDTypes;
    let formattedStats = null;
    const tickerKeys = Object.keys(tickerData ?? {});
    if (!tickerKeys?.length) {
      return null;
    }
    const statKeys = tickerKeys.filter((k) => k !== 'Symbol' && k !== '_id');
    formattedStats = {...tickerData};
    statKeys.forEach((column) => {
      if (!tickerData?.[column]?.length) {
        return;
      }
      const stats = tickerData?.[column]?.map((s) => {
        const keys = Object.keys(s ?? {});
        if (!keys?.length) {
          return null;
        }
        const formattedKeys = keys.reduce((acc, k) => {
          const parentKey = k;
          const foundKey = riskRewardDTypes?.find((d) => d?.key === k);
          if (foundKey?.type === 'object') {
            const subKeys = Object.keys(s?.[k] ?? {});
            if (!subKeys?.length) {
              return acc;
            }
            subKeys.forEach((sk) => {
              let value = s?.[k]?.[sk] ?? {value: null};
              if (value?.value === 'nan') {
                value = {value: null};
              }
              acc[`${parentKey}.${sk}`] = value;
            });
            return acc;
          }
          return {
            ...acc,
            [k]: s?.[k] ?? {value: null},
          };
        }, {});
        return {...formattedKeys, Symbol: tickerData?.Symbol};
      });
      formattedStats[column] = stats;
    });
    const rankKeys = tickerKeys.filter((k) => typeof k === 'string' && k?.includes('Rank'));
    if (rankKeys?.length) {
      formattedData.ranks = {};
      rankKeys.forEach((column) => {
        const columnName = column.replace('Rank', 'Stats')
        formattedData.ranks = {
          ...formattedData.ranks,
          [columnName]: tickerData?.[column]?.value,
        };
      });
    }
    formattedData.riskRewardData = formattedStats;
    if (Object.keys(formattedStats ?? {})?.length) {
      formattedData.dataTypes = ordered;
      formattedData.orderTypes = report?.asStatsOrder;
      formattedData.groupTitle = report?.fullTitle;
    }
  }
  return formattedData;
};

const searchRankPriority = {
  EXACTMATCH: 1000000,
  STARTSWITH: 100000,
  PARTIALMATCH: 10000,
  INCLUDES: 1000,
  MISSING: 0,
};

const RiskRewardTable = ({riskRewardData, riskRewardDataTypes, riskRewardTitle, riskRewardRanks}) => {
  const {
    symbols,
    selectedSymbol,
    setSelectedSymbol,
    theme,
    loading,
    refreshSymbolData,
    lastUpdated,
    selectedScanner,
    selectableScanners,
    updateSelectedReport,
    reportFilters,
    updateSelectedFilter,
    activeReportFilters,
    viewingDate,
  } = useContext(ReportsContext);

  const [statsColumn, setStatsColumn] = useState('Stats.Stats');
  const [currentRank, setCurrentRank] = useState(null);
  const [filteredSymbols, setFilteredSymbols] = useState(symbols);
  const [formattedDate, setFormattedDate] = useState(viewingDate ? dayjs(viewingDate).format('MMM D, YYYY') : null);

  useEffect(() => {
    if (viewingDate) {
      const newDate = dayjs(viewingDate).format('MMM D, YYYY');
      if (newDate !== formattedDate) {
        setFormattedDate(newDate);
      }
    }
  }, [viewingDate]);

  useEffect(() => {
    const filters = Object.values(activeReportFilters)?.filter((f) => f.selectedValue);
    if (filters?.length) {
      const columnFilter = filters.find((f) => f.selectedValue && f.filterType === 'stats');
      if (columnFilter) {
        setStatsColumn(columnFilter.selectedValue?.value);
      }
    }
  }, [activeReportFilters]);

  useEffect(() => {
      const column = activeReportFilters?.['Call/Put']?.selectedValue?.value ?? 'Stats.Stats';
      if (riskRewardRanks?.[column] !== undefined) {
        setCurrentRank(riskRewardRanks?.[column]);
      } else {
        setCurrentRank(null);
      }
  }, [activeReportFilters, riskRewardRanks]);

  useEffect(() => {
    if (!symbols?.length) {
      setFilteredSymbols([]);
      return;
    }
    const sorted = orderBy(symbols, (o) => {
      const itemValue = o?.label ?? o;
      return itemValue.toLowerCase();
    }, 'asc');
    setFilteredSymbols(sorted);
  }, [symbols]);

  const selectStyles = {
    control: (provided, state) => {
      if (theme === 'dark') {
        return {
          ...provided,
          background: '#1A1B25',
          border: '1px solid #70769B',
          color: '#70769B',
        };
      }
      return {
        ...provided,
      };
    },
    menu: (provided, state) => {
      if (theme === 'dark') {
        return {
          ...provided,
          background: '#1A1B25',
          border: '1px solid #70769B',
          color: '#70769B',
        };
      }
      return {
        ...provided,
      };
    },
    input: (provided, state) => {
      if (theme === 'dark') {
        return {
          ...provided,
          color: '#70769B',
        };
      }
      return {
        ...provided,
        color: '#464b45',
      };
    },
    singleValue: (provided, state) => {
      if (theme === 'dark') {
        return {
          ...provided,
          color: '#70769B',
        };
      }
      return {
        ...provided,
        color: '#464b45',
      };
    },
  };


  const hasNumbers = (t) => {
    const regex = /\d/g;
    return regex.test(t);
  };

  const handleInputChange = (v, action) => {
    if (action?.action !== 'input-change') {
      return;
    }
    const searchValue = v;
    if (!searchValue) {
      const sorted = orderBy(symbols, (o) => {
        const itemValue = o?.label ?? o;
        return itemValue.toLowerCase();
      }, 'asc');
      setFilteredSymbols(sorted);
      return;
    }
    const filtered = symbols?.filter((item) => {
      const itemValue = item?.label ?? item;
      return itemValue.toLowerCase().includes(searchValue.toLowerCase());
    });

    if (filtered?.length) {
      const formattedForOrderBy = filtered.map((item) => {
        const itemValue = item?.label ?? item;
        const tickerSymbol = hasNumbers(itemValue) && itemValue ? itemValue.split(/[0-9]/)[0] : itemValue;
        const splitValue = itemValue.split(searchValue)?.filter((t) => t?.length);
        let remainingSymbol = '';
        if (splitValue?.length) {
          remainingSymbol = splitValue[splitValue?.length - 1];
          if (remainingSymbol?.length > 1) {
            remainingSymbol = remainingSymbol?.slice(0, 1);
          }
        }
        if (tickerSymbol?.toLowerCase() === searchValue?.toLowerCase()) {
          return {
            rank: searchRankPriority.EXACTMATCH,
            ticker: tickerSymbol,
            remaining: remainingSymbol,
            label: item?.label,
            value: item?.value,
          };
        }
        if (tickerSymbol?.toLowerCase().startsWith(searchValue?.toLowerCase())) {
          return {
            rank: searchRankPriority.STARTSWITH,
            ticker: tickerSymbol,
            remaining: remainingSymbol,
            label: item?.label,
            value: item?.value,
          };
        }
        if (tickerSymbol?.slice(0, searchValue?.length)?.toLowerCase() === searchValue?.toLowerCase()) {
          return {
            rank: searchRankPriority.PARTIALMATCH,
            ticker: tickerSymbol,
            remaining: remainingSymbol,
            label: item?.label,
            value: item?.value,
          };
        }
        return {
          rank: searchRankPriority.INCLUDES,
          ticker: tickerSymbol,
          remaining: remainingSymbol,
          label: item?.label,
          value: item?.value,
        };

      });
      const sorted = orderBy(formattedForOrderBy, ['rank', 'remaining', 'ticker'], ['desc', 'asc', 'asc']);
      setFilteredSymbols(sorted.map((item) => ({label: item?.label, value: item?.value})));
      return;
    }

    setFilteredSymbols([]);
  }

  return (
    <div className="mt-4">
      {/* Header */}
      <div className="flex items-start justify-between flex-col gap-2">
        <div className="power-options">
          <h1>{selectedScanner?.label ?? riskRewardTitle ?? ''}  {formattedDate ? `- ${formattedDate}` : null}</h1>
          {selectedSymbol ? <h1>{selectedSymbol?.label}</h1> : null}
        </div>
      </div>
      <div>
        {/* Select */}
        <div className="max-w-4xl flex gap-4 mt-4">
          <div className={`${currentRank !== null ? 'w-3/12' : 'w-1/3'}`}>
            {selectedScanner?.value && selectableScanners?.length ? (
              <Select
                cacheOptions
                options={selectableScanners}
                onChange={(e) => {
                  updateSelectedReport(e);
                }}
                value={selectedScanner ?? null}
                styles={selectStyles}
                isSearchable={false}
              />
            ) : null}
          </div>
          <div className={`${currentRank !== null ? 'w-3/12' : 'w-1/3'}`}>
            {selectedScanner?.value && symbols?.length ? (
              <Select
                // cacheOptions
                options={filteredSymbols}
                onChange={(e) => {
                  if (reportFilters && reportFilters?.length) {
                    updateSelectedFilter(
                      reportFilters[0].label,
                      reportFilters[0].values[0].value,
                      reportFilters[0].values[0].label,
                    );
                  }
                  setSelectedSymbol(e);
                }}
                isLoading={loading}
                value={selectedSymbol ?? null}
                styles={selectStyles}
                isSearchable
                onInputChange={(value, action) => handleInputChange(value, action)}
                // filterOptions={createFilter({ignoreAccents: false, matchFrom: 'start', trim: true, ignoreCase: true})}
              />
            ) : null}
          </div>
          {reportFilters?.length && selectedScanner?.value && selectedSymbol?.value
            ? reportFilters.map((filter) =>
                filter?.type === 'dropdown-select' ? (
                  <div className="w-1/3" key={filter.label}>
                    <Select
                      cacheOptions
                      options={filter.values}
                      onChange={(e) => {
                        updateSelectedFilter(filter.label, e.value, e.label);
                      }}
                      placeholder={filter?.label}
                      value={activeReportFilters?.[filter.label]?.selectedValue ?? 'Stats.Stats'}
                      styles={selectStyles}
                    />
                  </div>
                ) : null,
              )
            : null}

          {currentRank !== null ? (
            <div className="w-3/12 flex items-center justify-start">
              <p className="text-sm text-header-color">
                Rank: {currentRank}
              </p>
            </div>
          ) : null}
        </div>
        {/* Stats Table */}
        {loading ? (
          <div className="reports-page-loading flex items-center justify-center">
            <div className="lds-ellipsis">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        ) : riskRewardDataTypes?.length && riskRewardData ? (
          <>
            {lastUpdated && lastUpdated?.symbol === selectedSymbol?.value ? (
              <div className="flex items-center justify-end gap-3 w-full">
                <p className="text-xs text-header-color">Last Updated: {lastUpdated?.datetime}</p>{' '}
                <button className="text-xs text-header-color" type="button" onClick={refreshSymbolData}>
                  <FontAwesomeIcon icon={faRefresh} />
                </button>
              </div>
            ) : null}
            <table className="min-w-full divide-y divide-gray-300 mt-4">
              <thead>
                <tr className="divide-x divide-gray-200">
                  {riskRewardDataTypes?.length
                    ? riskRewardDataTypes?.map((type) => (
                        <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold">
                          {type?.title ?? type}
                        </th>
                      ))
                    : null}
                </tr>
              </thead>
              <tbody className="divide-y  divide-gray-200">
                {riskRewardData[statsColumn]?.length
                  ? riskRewardData[statsColumn]?.map((d, i) => (
                      <tr key={`risk-reward-${d?.Symbol?.value}-${i * 5} `} className="divide-x divide-gray-300">
                        {riskRewardDataTypes?.map((type) => {
                          const value = d?.[type?.key];
                          return (
                            <td
                              key={`risk-reward-${type?.key}`}
                              className="px-3 py-2 whitespace-nowrap text-sm report-item"
                            >
                              {displayValue({data: value, dataType: type})}
                            </td>
                          );
                        })}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </>
        ) : null}
      </div>
    </div>
  );
};

const Report = () => {
  const [riskRewardData, setRiskRewardData] = useState(null);
  const [riskRewardDataTypes, setRiskRewardDataTypes] = useState(null);
  const [riskRewardOrder, setRiskRewardOrder] = useState(null);
  const [riskRewardTitle, setRiskRewardTitle] = useState(null);
  const [riskRewardRanks, setRiskRewardRanks] = useState(null);
  const {report, selectedSymbol, symbolData, reportSettings} = useContext(ReportsContext);

  const resetData = () => {
    setRiskRewardData(null);
    setRiskRewardDataTypes(null);
    setRiskRewardOrder(null);
    setRiskRewardTitle(null);
  };

  useEffect(() => {
    resetData();
  }, [selectedSymbol]);

  useEffect(() => {
    if (symbolData) {
      if (!report) {
        if (riskRewardData) setRiskRewardData(null);
        return;
      }
      const filterForMatchingSymbol = symbolData.data.find((d) => d?.Symbol?.value === selectedSymbol?.value);
      const formattedData = formatSymbolData(report, reportSettings, filterForMatchingSymbol);
      if (formattedData) {
        setRiskRewardData(formattedData.riskRewardData);
        setRiskRewardDataTypes(formattedData.dataTypes);
        setRiskRewardOrder(formattedData.orderTypes);
        setRiskRewardTitle(formattedData.groupTitle);
        setRiskRewardRanks(formattedData.ranks);
      }
    } else {
      setRiskRewardData(null);
      setRiskRewardDataTypes(null);
      setRiskRewardOrder(null);
      setRiskRewardTitle(null);
      setRiskRewardRanks(null);
    }
  }, [symbolData]);

  return (
    <div className="w-full mt-4 report">
      <div className="report-table">
        <RiskRewardTable
          riskRewardData={riskRewardData}
          riskRewardDataTypes={riskRewardDataTypes}
          riskRewardTitle={riskRewardTitle}
          riskRewardRanks={riskRewardRanks}
        />
      </div>
    </div>
  );
};

export default Report;
