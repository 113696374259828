import React, {useState, useEffect} from 'react';

const OptionView = ({optionToView, groupCollection, optionsLayout}) => {
  const [optionData, setOptionData] = useState(null);

  useEffect(() => {
    if (!optionToView || !groupCollection) {
      if (optionData) setOptionData(null);
      return;
    }
    if (optionToView) {
      const {group, symbol} = optionToView;
      const collectionData = groupCollection[group];
      if (collectionData && collectionData?.data && collectionData?.dataTypes) {
        if (collectionData?.data?.[symbol]) {
          setOptionData(collectionData.data?.[symbol]);
        }
      } else if (optionData) {
        setOptionData(null);
      }
    }
  }, [optionToView, groupCollection]);

  if (optionToView && !optionData) {
    return (
      <div>
        <div className="flex items-start justify-between flex-col gap-2">
          <div className="power-options">
            <h1>Power Options</h1>
            <h3>Daily Squeeze Only</h3>
          </div>
          <h2 className="text-xl font-bold">{optionData?.Symbol?.value}</h2>
        </div>
        <div>
          <div className="my-4 text-center">
            <div className="loader" />
          </div>
        </div>
      </div>
    );
  }

  return optionData ? (
    <div>
      <div className="flex items-start justify-between flex-col gap-2">
        <div className="power-options">
          <h2 className="text-[28px] -mb-1 font-bold">{optionData?.Symbol?.value}</h2>
          <h1>Power Options</h1>
          <h3>Daily Squeeze Only</h3>
        </div>
      </div>
      <div>
        <div className=" flow-root">
          <div className="-my-2 overflow-x-hidden ">
            <div className="inline-block min-w-full align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">
                      <span />
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold ">
                      <span />
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {Object.values(optionsLayout)?.length
                    ? Object.values(optionsLayout).map((val) => {
                        const {keys, label, color} = val;
                        return (
                          <tr key={label}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-0">
                              <div className="option-wrap">
                                <div className="option-label" style={{backgroundColor: color}}>
                                  <span>{label}</span>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-pre-wrap px-3 py-4 text-sm ">
                              {keys.map((k, i) => {
                                const prevKey = i - 1 >= 0 ? keys[i - 1] : keys[0];
                                if (
                                  !optionData[k]?.value ||
                                  (k.includes('Ty') && prevKey && !optionData[prevKey]?.value)
                                ) {
                                  if (i === 0) {
                                    return <span key={k}>No Power Option Play Available</span>;
                                  }
                                  return null;
                                }
                                if (optionData[k]?.value) {
                                  if (k.includes('Ex')) {
                                    return (
                                      <span key={k}>
                                        Exp: <span className="font-semibold">{optionData[k]?.value}</span> <br />
                                      </span>
                                    );
                                  }
                                  if (k.includes('Stk')) {
                                    return (
                                      <span key={k}>
                                        Strike: <span className="font-semibold">{optionData[k]?.value}</span>
                                      </span>
                                    );
                                  }
                                  if (k.includes('Ty')) {
                                    return (
                                      <span key={k}>
                                        <br />
                                        Type: <span className="font-semibold">{optionData[k]?.value}</span>
                                      </span>
                                    );
                                  }
                                }
                                return null;
                              })}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const optionValuesSame = (optionsLayout, prev, next) => {
  const optionsLayoutValues = Object.values(optionsLayout ?? {});
  if (!optionsLayoutValues?.length) {
    return true;
  }
  const {groupCollection: prevGroupCollection, optionToView: prevOptionToView} = prev ?? {
    groupCollection: null,
    optionToView: null,
  };
  const {groupCollection: nextGroupCollection, optionToView: nextOptionToView} = next ?? {
    groupCollection: null,
    optionToView: null,
  };
  const {group: prevGroup, symbol: prevSymbol} = prevOptionToView ?? {group: null, symbol: null};
  const {group: nextGroup, symbol: nextSymbol} = nextOptionToView ?? {group: null, symbol: null};
  const {data: prevData} = prevGroupCollection?.[prevGroup] ?? {data: null};
  const {data: nextData} = nextGroupCollection?.[nextGroup] ?? {data: null};
  if (!prevData && !nextData) {
    return true;
  }
  const prevSymbolData = prevData?.[prevSymbol];
  const nextSymbolData = nextData?.[nextSymbol];

  if (JSON.stringify(prevSymbolData) !== JSON.stringify(nextSymbolData)) {
    const areSame = optionsLayoutValues.every((l) => {
      const {keys} = l;
      return keys.every(
        (k) => JSON.stringify(prevSymbolData?.[k]?.value) === JSON.stringify(nextSymbolData?.[k]?.value),
      );
    });
    return areSame;
  }
  return true;
};

export default React.memo(OptionView, (prev, next) => {
  const optionToViewSame =
    prev.optionToView?.group === next.optionToView?.group && prev.optionToView?.symbol === next.optionToView?.symbol;
  const optionsLayoutSame = JSON.stringify(prev.optionsLayout) === JSON.stringify(next.optionsLayout);
  return optionsLayoutSame && optionToViewSame && optionValuesSame(next?.optionsLayout, prev, next);
});

// export default OptionView;
