import {createContext} from 'react';

const ReportsContext = createContext({
  report: {},
  symbols: [],
  selectedSymbol: null,
  setSelectedSymbol: () => {},
  symbolData: {},
  reportSettings: {},
  theme: 'light',
  allReports: [],
  loading: false,
  loadingAllReports: false,
  refreshSymbolData: () => {},
  lastUpdated: null,
  selectableScanners: [],
  scannersMeta: {},
  selectedScanner: null,
  setSelectedScanner: () => {},
  updateSelectedReport: () => {},
});

export default ReportsContext;
