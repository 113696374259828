import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Scanner from '../Components/Scanner/Scanner';
import ScannerHeader from '../Components/Header/ScannerHeader';
import AdModal from '../Components/Modal/AdModal';
import Chart from '../Components/Chart/Chart';

const DisplayScanners = ({groupCollection, groups, secondaryGroups, dispatch, theme, setTheme, wsSocket}) => {
  const [filteredForSecondary, setFilteredForSecondary] = useState(
    groups?.filter((gr) => !groupCollection[gr.group]?.settings?.secondaryView),
  );
  useEffect(() => {
    if (!groups?.length) return;
    if (
      JSON.stringify(groups?.filter((gr) => !groupCollection[gr.group]?.settings?.secondaryView)) !==
      JSON.stringify(filteredForSecondary)
    ) {
      setFilteredForSecondary(groups?.filter((gr) => !groupCollection[gr.group]?.settings?.secondaryView));
    }
  }, [groups, groupCollection]);

  if (
    !Object.keys(groupCollection || {}).length ||
    !groups?.length ||
    !groups?.filter((gr) => !groupCollection[gr.group]?.settings?.secondaryView)?.length
  )
    return null;

  return filteredForSecondary?.length
    ? filteredForSecondary.map((group) => (
        <div
          className={`${
            Object.keys(groupCollection || {}).length > 1 && !secondaryGroups?.length
              ? 'multi-screen-scanner scanner-group'
              : ' scanner-group'
          }`}
          key={group}
        >
          <Scanner
            group={groupCollection[group.group] ?? null}
            groupNumber={groups.length}
            dispatch={dispatch}
            multiScreen={Object.keys(groupCollection || {}).length > 1}
            theme={theme}
            setTheme={setTheme}
            wsSocket={wsSocket}
          />
          <div className="clearfix" />
        </div>
      ))
    : null;
};

const DisplaySecondaryScanners = ({groupCollection, secondaryGroups, dispatch, theme, setTheme, wsSocket}) => {
  if (!secondaryGroups?.length || !Object.keys(groupCollection || {}).length) return null;

  return secondaryGroups.map((group) => (
    <div className="scanner-group reduced-list secondaryScanner" key={group}>
      <Scanner
        group={groupCollection[group.group] ?? null}
        groupNumber={secondaryGroups.length}
        dispatch={dispatch}
        multiScreen={Object.keys(groupCollection || {}).length > 1}
        theme={theme}
        setTheme={setTheme}
        wsSocket={wsSocket}
      />
    </div>
  ));
};

const Grid = ({
  scannersData,
  groupCollection,
  groups,
  secondaryGroups,
  dispatch,
  theme,
  setTheme,
  wsSocket,
  loadingGroups,
  modalShow,
  handleClose,
  userData,
  showChart,
  closeChart,
  symbolData,
  chartSettings,
  getSymbolData,
  symbolToChart,
}) => {
  const params = useParams();
  const scannerId = params?.id;

  const scannerData = scannersData?.find((s) => s.slug === scannerId);
  const scannerTitle = scannerData?.title;


  if (!groupCollection || !Object.keys(groupCollection || {}).length || !groups?.length || loadingGroups) return null;

  return (
    <>
      <AdModal open={modalShow} handleClose={handleClose} freeUser={userData?.free_access} />
      <div className="chartVisible">
        <div className="mainScannerColumn">
          <ScannerHeader
            name={scannerTitle}
            theme={theme}
            setTheme={setTheme}
            userData={userData}
            showChart={showChart}
            groups={groups}
            wsSocket={wsSocket}
          />
          <DisplayScanners
            groupCollection={groupCollection}
            groups={groups}
            secondaryGroups={secondaryGroups}
            dispatch={dispatch}
            theme={theme}
            setTheme={setTheme}
            wsSocket={wsSocket}
          />
        </div>
        <div className="chartWrap">
          <Chart
            theme={theme}
            wsSocket={wsSocket}
            chartSettings={chartSettings}
            symbolData={symbolData}
            symbolToChart={symbolToChart}
            getSymbolData={getSymbolData}
            groupCollection={groupCollection}
            closeChart={closeChart}
          />
          {secondaryGroups?.length ? (
            <DisplaySecondaryScanners
              groupCollection={groupCollection}
              secondaryGroups={secondaryGroups}
              dispatch={dispatch}
              theme={theme}
              setTheme={setTheme}
              wsSocket={wsSocket}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Grid;
