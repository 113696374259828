import React, {useRef, useState, useEffect} from 'react';
import {createChart} from 'lightweight-charts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHurricane} from '@fortawesome/free-solid-svg-icons';
import {ReactComponent as CloseIcon} from '../../Icons/Close.svg';

const Chart = ({chartSettings, symbolToChart, symbolData, closeChart}) => {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);
  const [mainSeries, setMainSeries] = useState(null);
  const [additionalSeries, setAdditionalSeries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const toCandlestickData = (data) => {
    if (!data?.length) return [];
    return data
      .filter((d) => {
        const {Date, High, Low, Open, Close} = d;
        return Date && High && Low && Open && Close;
      })
      .map((d) => {
        const {Date, High, Low, Open, Close} = d;
        return {time: Date, open: Open, high: High, low: Low, close: Close};
      });
  };

  const initToolTip = (candleStickInst = null, chartInstance = null) => {
    const candleInst = candleStickInst ?? mainSeries;
    const chartInst = chartInstance ?? chart;
    if (!chartRef.current || !candleInst) return;
    const container = chartRef.current;
    const toolTipWidth = 80;
    const toolTipHeight = 80;
    const toolTipMargin = 15;

    // Create and style the tooltip html element
    const toolTip = document.createElement('div');
    toolTip.style = `width: 180px; height: auto; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 2px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
    toolTip.style.background = 'white';
    toolTip.style.color = 'black';
    toolTip.style.borderColor = '#41E241';
    container.appendChild(toolTip);

    // update tooltip
    chartInst.subscribeCrosshairMove((param) => {
      if (
        param.point === undefined ||
        !param.time ||
        param.point.x < 0 ||
        param.point.x > container.clientWidth ||
        param.point.y < 0 ||
        param.point.y > container.clientHeight
      ) {
        toolTip.style.display = 'none';
      } else {
        // time will be in the same format that we supplied to setData.
        // thus it will be YYYY-MM-DD
        const dateStr = param.time;
        toolTip.style.display = 'block';
        const data = param.seriesData.get(candleInst);
        const {open, high, low, close} = data;
        const price = data.value !== undefined ? data.value : data.close;
        toolTip.innerHTML = `
        <div class="flex flex-row">
          <div class="basis-1/2">
            <div style="font-size: 12px; margin: 2px 0px; color: ${'black'}">
              Open
            </div>
            <div style="font-size: 14px; margin: 2px 0px; color: ${'black'}">
              ${Math.round(100 * open) / 100}
            </div>
          </div>
          <div class="basis-1/2">
            <div style="font-size: 12px; margin: 2px 0px; color: ${'black'}">
              Close
              </div>
            <div style="font-size: 14px; margin: 2px 0px; color: ${'black'}">
              ${Math.round(100 * close) / 100}
            </div>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="basis-1/2">
            <div style="font-size: 12px; margin: 2px 0px; color: ${'black'}">
              High
            </div>
            <div style="font-size: 14px; margin: 2px 0px; color: ${'black'}">
              ${Math.round(100 * high) / 100}
            </div>
          </div>
          <div class="basis-1/2">
            <div style="font-size: 12px; margin: 2px 0px; color: ${'black'}">
              Low
            </div>
            <div style="font-size: 14px; margin: 2px 0px; color: ${'black'}">
              ${Math.round(100 * low) / 100}
            </div>
          </div>
        </div>
        <div style="color: ${'black'}">
          ${dateStr}
			  </div>`;

        const coordinate = candleInst.priceToCoordinate(price);
        let shiftedCoordinate = param.point.x - 50;
        if (coordinate === null) {
          return;
        }
        shiftedCoordinate = Math.max(0, Math.min(container.clientWidth - toolTipWidth, shiftedCoordinate));
        const coordinateY =
          coordinate - toolTipHeight - toolTipMargin > 0
            ? coordinate - toolTipHeight - toolTipMargin
            : Math.max(0, Math.min(container.clientHeight - toolTipHeight - toolTipMargin, coordinate + toolTipMargin));
        toolTip.style.left = `${shiftedCoordinate}px`;
        toolTip.style.top = `${coordinateY}px`;
      }
    });

    
    const legend = document.createElement('div');
    legend.style = `position: absolute; left: 12px; top: 12px; z-index: 1; font-size: 14px; font-family: sans-serif; line-height: 18px; font-weight: 300;`;
    container.appendChild(legend);
    if (chartSettings?.additionalSeries?.includes('BOLLINGER_BAND')) {
      const firstRow = document.createElement('div');
      firstRow.innerHTML = `<div><span style="color: rgb(255,165,0);">&#x25cf;</span> Bollinger Band</div>`;
      firstRow.style.color = 'black';
      legend.appendChild(firstRow);
    }
    if (chartSettings?.additionalSeries?.includes('20_DAY_MOVING_AVERAGE')) {
      const firstRow = document.createElement('div');
      firstRow.innerHTML = `<div><span style="color: rgb(40,200,0);">&#x25cf;</span> 20 Day SMA</div>`;
      firstRow.style.color = 'black';
      legend.appendChild(firstRow);
    }
    if (chartSettings?.additionalSeries?.includes('50_DAY_MOVING_AVERAGE')) {
      const firstRow = document.createElement('div');
      firstRow.innerHTML = `<div><span style="color: rgb(200,40,0);">&#x25cf;</span> 50 Day SMA</div> `;
      firstRow.style.color = 'black';
      legend.appendChild(firstRow);
    }
    

  };

  const initChart = () => {
    if (!chartRef.current || chart) return null;
    const newChart = createChart(chartRef.current, {
      height: 300,
      rightPriceScale: {
        scaleMargins: {
          top: 0.2,
          bottom: 0.2,
        },
        borderVisible: false,
      },
      timeScale: {
        borderVisible: false,
      },
      layout: {
        backgroundColor: '#ffffff',
        textColor: '#333',
      },
      grid: {
        horzLines: {
          color: '#eee',
        },
        vertLines: {
          color: '#ffffff',
        },
      },
      crosshair: {
        vertLine: {
          labelVisible: false,
        },
      },
    });
    setChart(newChart);
    return newChart;
  };
  
  /**
   * Calculates simple moving average
   * @param {Array} data 
   * @param {Number} period 
   * @returns 
   */
  const calculateSMA = (data, period) => {
    if (data.length < period) {
      throw new Error('Not enough data points to calclate SMA');
    }

    return data.slice(-period).reduce((sum, price) => sum + price) / period;
  };

  /**
   * Calculates Bollinger Bands
   * @param {Array} closeData 
   * @param {Number} bollingerPeriod 
   * @param {Number} stdDevFactor 
   * @returns 
   */
  const calculateBollingerBands = (closeData, bollingerPeriod = 20, stdDevFactor = 2) => {
    if (closeData.length < bollingerPeriod) {
      throw new Error("Not enough data points to calculate Bollinger Bands");
    }

    // Calculate the Standard Deviation
    function calculateStdDev(data, period, sma) {
      const variance = data
        .slice(-period)
        .reduce((sum, price) => sum + ((price - sma) ** 2), 0) / period;
      return Math.sqrt(variance);
    }
    const sma = calculateSMA(closeData, bollingerPeriod);
    const stdDev = calculateStdDev(closeData, bollingerPeriod, sma);
    // Calculate the Bollinger Bands
    const upperBand = sma + stdDevFactor * stdDev;
    const lowerBand = sma - stdDevFactor * stdDev;
    return {
      sma,
      upperBand,
      lowerBand
    };
  };
  
  /**
   * Converts all data points to Bollinger Band data
   * @param {Array} data 
   * @returns 
   */
  const toBollingerBandData = (data) => {
    const DAY_BOLLINGER_BAND_PERIOD = 20;
    const arr = data.map((d, index, original) => {
      let row = {};
      if (index >= DAY_BOLLINGER_BAND_PERIOD) {
        const temp = original.slice(index-DAY_BOLLINGER_BAND_PERIOD, index).map(a => a.Close);
        row = calculateBollingerBands(temp, DAY_BOLLINGER_BAND_PERIOD);
      }
      return {...row, ...d};
    });
    return arr;
  };

  /**
   * Converts data to simple moving average based on period
   * @param {Array} data 
   * @param {number} period 
   * @returns Array
   */
  const toMovingAverageData = (data, period = 20) => {
    const arr = data.map((d, index, original) => {
      let sma;
      if (index >= period) {
        const temp = original.slice(index-period, index).map(a => a.Close);
        sma = calculateSMA(temp, period);
      }
      return {sma, ...d};
    });
    return arr;


  };

  const toSeriesData = (data, type) => {
    switch(type) {
      case 'LOWER_BOLLINGER_BAND':
        return toBollingerBandData(data).map((d) => ({ time: d.Date, value: d.upperBand }));
      case 'UPPER_BOLLINGER_BAND':
        return toBollingerBandData(data).map((d) => ({ time: d.Date, value: d.lowerBand }));
      case '20_DAY_MOVING_AVERAGE':
        return toMovingAverageData(data, 20).map((d) => ({ time: d.Date, value: d.sma}));
      case '50_DAY_MOVING_AVERAGE':
        return toMovingAverageData(data, 50).map((d) => ({ time: d.Date, value: d.sma}));
      case 'CLOSE':
        return data.map((d) => ({time: d.Date, value: d.Close}));
      default:
        return null;
    }
  }
    

  const initAdditionalSeries = (data, chartInstance = null) => {
    let chartInst = chartInstance ?? chart;
    if (!chartInst) {
      chartInst = initChart();
    }

    if (chartSettings.additionalSeries) {
      const theseAdditionalSeries = [];
  
      if (chartSettings.additionalSeries.includes('BOLLINGER_BAND')) {
        const upperBollingerBand = chartInst.addLineSeries({color: 'rgb(255,165,0)', lineWidth: 1});
        upperBollingerBand.setData(toSeriesData(data, 'UPPER_BOLLINGER_BAND'));
        theseAdditionalSeries.push({series: upperBollingerBand, type: 'UPPER_BOLLINGER_BAND'});
    
        const lowerBollingerBand = chartInst.addLineSeries({color: 'rgb(255,165,0)', lineWidth: 1});
        lowerBollingerBand.setData(toSeriesData(data, 'LOWER_BOLLINGER_BAND'));
        theseAdditionalSeries.push({series: lowerBollingerBand, type: 'LOWER_BOLLINGER_BAND'});
      }

      
      if (chartSettings.additionalSeries.includes('20_DAY_MOVING_AVERAGE')) {
        const twentyDaySMA = chartInst.addLineSeries({color: 'rgb(40,200,0)', lineWidth: 1});
        twentyDaySMA.setData(toSeriesData(data, '20_DAY_MOVING_AVERAGE'));
        theseAdditionalSeries.push({series: twentyDaySMA, type: '20_DAY_MOVING_AVERAGE'});
      }
  
      if (chartSettings.additionalSeries.includes('50_DAY_MOVING_AVERAGE')) {
        const fiftyDaySMA = chartInst.addLineSeries({color: 'rgb(200,40,0)', lineWidth: 1});
        fiftyDaySMA.setData(toSeriesData(data, '50_DAY_MOVING_AVERAGE'));
        theseAdditionalSeries.push({series: fiftyDaySMA, type: '50_DAY_MOVING_AVERAGE'});
      }
  
  
      setAdditionalSeries(theseAdditionalSeries);

    }


  };

  const initLineSeries = (data, chartInstance = null) => {
    let chartInst = chartInstance ?? chart;
    if (!chartInst) {
      chartInst = initChart();
    }

    const lineSeriesChart = chartInst.addLineSeries({color: '#26a69a', lineWidth: 1});
    lineSeriesChart.setData(toSeriesData(data, 'CLOSE'));
    setMainSeries(lineSeriesChart);
    chartInst.timeScale().fitContent();
    initToolTip(lineSeriesChart, chartInst);

  };


  const initCandleStick = (candleStickData, chartInstance = null) => {
    let chartInst = chartInstance ?? chart;
    if (!chartInst) {
      chartInst = initChart();
    }

    const candleStickChart = chartInst.addCandlestickSeries({
      upColor: '#26a69a',
      downColor: '#ef5350',
      borderVisible: false,
      wickUpColor: '#26a69a',
      wickDownColor: '#ef5350',
    });
    candleStickChart.setData(candleStickData);
    setMainSeries(candleStickChart);
    chartInst.timeScale().applyOptions({
      barSpacing: 10
    });
    // chartInst.timeScale().fitContent();
    initToolTip(candleStickChart, chartInst);
  };

  const removeChart = (chartInstance = null) => {
    const chartInst = chartInstance ?? chart;
    if (!chartInst) return;
    chartInst.remove();
    setChart(null);
    setMainSeries(null);
  };

  const displayChartData = (candleStickData, data, chartInstance = null) => {
    console.log(data);
    const chartInst = chartInstance ?? chart;
    if (!chartInst) return;
    if (chartSettings?.type === 'candlestick') {
      if (!mainSeries) {
        initCandleStick(candleStickData, chartInstance);
        initAdditionalSeries(data, chartInstance);
        setIsLoading(false);
        return;
      }
      mainSeries.setData(candleStickData);
      additionalSeries.forEach(s => {
        s.series.setData(toSeriesData(data, s.type));
      });
      chartInst.timeScale().applyOptions({
        barSpacing: 10
      });

    } else if (chartSettings?.type === 'line') {
      if (!mainSeries) {
        initLineSeries(data, chartInstance);
        initAdditionalSeries(data, chartInstance);
        setIsLoading(false);
        return;
      }
      mainSeries.setData(toSeriesData(data, 'CLOSE'));
      additionalSeries.forEach(s => {
        s.series.setData(toSeriesData(data, s.type));
      });
    }
    // chartInst.timeScale().fitContent();
    setIsLoading(false);
  };


  useEffect(() => {
    const chartInstance = initChart();
    if (symbolToChart && symbolData?.length) {
      try {
        const candleStickData = toCandlestickData(symbolData);
        displayChartData(candleStickData, symbolData, chartInstance);
      } catch (e) {
        setTimeout(() => {
          const candleStickData = toCandlestickData(symbolData);
          displayChartData(candleStickData, symbolData, chartInstance);
        }, 1200);
      }

    }

    return () => {
      console.log('remove chart');
      if (chart) {
        setIsLoading(true);
        removeChart();
      }
    };
  }, []);

  useEffect(() => {
    if (!chartRef.current) return;
    if (!chart || !symbolData?.length) return;

    const candleStickData = toCandlestickData(symbolData);
    displayChartData(candleStickData, symbolData);
  }, [symbolData]);

  return (
    <div className="chart-container flex flex-col mb-4">
      <div className="flex justify-between items-center">
        {symbolToChart ? (
          <h1 className="text-header-color font-bold text-[20px] leading-[24px] mb-4">{symbolToChart}
          {isLoading ? <FontAwesomeIcon className="px-4" icon={faHurricane} spin /> : '' }
          </h1>
        ) : null}
        <button onClick={closeChart} type="button">
          <CloseIcon className="h-5 w-5 text-header-color" />
        </button>
      </div>
      <div className="chart" ref={chartRef} />
    </div>
  );
};

export default Chart;
